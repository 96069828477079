.skin-green.sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 18px;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-left: -15px;
    margin-right: -15px;
}
.skin-green .main-header .logo .logo-lg {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.skin-green .main-header li.user-header {
	background-color: transparent !important;
}
/* .skin-green .content-header {
	background: transparent !important;
} */

/* .content-wrapper {
	z-index: 1050 !important;
} */
.content-header>h1 {
	font-size: 32px !important;
}
.sidebar-menu>li>a {
	padding: 8px 5px 8px 15px !important;
	font-size: 13px !important;
}

.center_content ul{ float: left; display: table;width: 96%;height: 75px;}
.center_content h1 {
    font-size: 40px;
    line-height: 1.3em;
    margin: 0;
}
.center_content p{ margin:0px;font-size: 18px;}
.center_content ul li {
  display: table-cell;
  padding: 7px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  vertical-align: middle;
}
.center_content ul li:nth-child(1){ width:65%; text-align:center;}
.center_content ul li:nth-child(2){ width:20%;padding-right:15px;}
.center_content ul li:nth-child(3){ width:15%; padding-left:0px; cursor:pointer;}
.main-header .logo{ height: 75px !important;}
.center_content li a {
    padding: 38px 24px 19px;
}
.center_content ul li:last-child {border: none; padding: 0;}
.center_content ul li .fa.fa-gears {
  color: #fff;
  font-size: 40px;
}
.center_content .user-header img {
  border-radius: 50%;
  height: 35px;
  margin: 0 auto;
  width: 35px;
}


.sidebar-mini.sidebar-collapse .main-sidebar{
 padding-top: 107px; 
}
.sidebar-mini.sidebar-collapse .main-sidebar .sidebar-menu .active::after{
  border:none;
}

/* @media (min-width:1200px) and (max-width:1360px){
.center_content li a {
    padding: 38px 19px 19px;
}

} */
@media (min-width:992px) and (max-width:1360px){
.center_content li a {
    padding: 36px 14px 20px;
}
.center_content ul li:nth-child(2) {
    padding-left: 10px;
}
.center_content ul {
  width: 94%;
}
.center_content h1 {
  font-size: 32px;
}  
.center_content p {
    font-size: 14px;
}
.center_content ul li .fa.fa-gears {
    font-size: 35px;
}
}
@media (min-width:768px) and (max-width:991px){
.cropModal-content {
    width: 70%;
}
.center_content ul li .fa.fa-gears {
    font-size: 30px;
}
.center_content ul {
    width: 92%;
}
.center_content h1 {
    font-size: 20px;
}
.center_content p {
    font-size: 13px;
}
.center_content li a {
    padding: 20px 5px;
}
.center_content ul li:nth-child(1) {
    width: 52%;
}
.center_content ul li:nth-child(2) {
    padding-left:5px;
	width: 25%;
}
.center_content ul li:nth-child(3) {
    width: 23%;
}
}
@media(max-width:767px){
.cropModal-content {
    width: 85%;
}
.center_content ul {
    display: block;
    height: 100%;
    width: 100%;
	float:left;
}
.center_content ul li:nth-child(1) {
    text-align: center;
    width: 100%;
	display:block;
	border-right:0;
	border-bottom:1px solid rgba(0, 0, 0, 0.3);
}
.main-header .center_content ul li {
    padding: 7px 0px;
}
.center_content ul li:nth-child(2) {
	padding-left:5px;
}
.center_content ul li:nth-child(3) {
    width: 20%;
}

.center_content li a {
    padding: 31px 11px 19px;
}
.center_content h1 {
    font-size: 20px;
}
.center_content p {
    font-size: 14px;
}
.center_content ul li .fa.fa-gears {
    font-size: 30px;
}
.main-header {
    max-height: 185px !important;
}
}
pagination-template{ bottom:0px;}